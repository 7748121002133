import React,{ Component } from 'react'

class Home extends Component {
     render(){
          return(
               <div>
       
       <main>

 
<div class="slider-area ">
    <div class="slider-active">
       
        
        
        <div class="single-slider slider-height d-flex align-items-center">
            <div class="container">
                <div class="row">
               
                    
          <div class="col-xl-6 col-lg-6 col-md-8">
                        <div class="hero__caption">
                        
                           <h1 data-animation="fadeInUp" data-delay=".6s">Desire Global Solutions</h1>
                            <span data-animation="fadeInUp" data-delay=".4s">Web, Software & DevOps Solutions</span>
                            
                            {/* <div class="hero__btn">
                                <a href="industries.html" class="btn hero-btn"  data-animation="fadeInLeft" data-delay=".8s">Learn More</a>
                                <a href="industries.html" class="btn border-btn ml-15" data-animation="fadeInRight" data-delay="1.0s">Hire Me</a>
                            </div> */}
                        </div>
                    </div>




                </div>
            </div>
        </div>
    </div>
</div>
 
<section class="about-area section-paddingt30">
    <div class="container">
        <div class="row ">
            <div class="col-lg-6">
                <div class="about-caption">
                    <h3>DevOps Solutions</h3>
                        <p class="pera1">
The firm <b>Desire Global Solutions</b> provide the DevOps Solutions according to global desire.</p> 
 
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-caption">
                <h3>Websites & Softwares</h3>
                <p class="pera1">
The firm <b>Desire Global Solutions</b> make a Websites & Softwares according to gloal desire.</p>
                   {/* <div class="send-cv">
                        <a href="#">info@desireglobalsolutions.com</a>

                   </div> */}
                </div>
            </div>
        </div>
    </div>
</section>
 
<section class="categories-area section-padding3">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
              
                <div class="section-tittle mb-70">
                    <h2>What Services you will Get from us!</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-cat text-center mb-50">
                    <div class="cat-icon">
                        <span class="flaticon-pen"></span>
                    </div>
                    <div class="cat-cap">
                        <h4><b>UI/UX Design</b></h4>
                        <p>The firm <b>Desire Global Solutions</b> working in UI/UX Design of different Softwares and Websites. Our goal is global desire.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-cat text-center mb-50">
                    <div class="cat-icon">
                        <span class="flaticon-speaker"></span>
                    </div>
                    <div class="cat-cap">
                        <h4><b>Digital Marketing</b></h4>
                        <p>The firm <b>Desire Global Solutions</b> working in Digital Marketing Ideas and Soutions. We work according to global desire.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-cat text-center mb-50">
                    <div class="cat-icon">
                        <span class="flaticon-portfolio"></span>
                    </div>
                    <div class="cat-cap">
                        <h4><b>Website Development</b></h4>
                        <p>The firm <b>Desire Global Solutions</b> working in Business, Ecommerce & Software Websites development</p>
                    </div>
                </div>
            </div>
         </div>
    </div>
</section>
 
<section class="services-area  services-padding">
    <div class="container">
       <div class="project-heading mb-35">
            <div class="row align-items-end">
                <div class="col-lg-6">
                    
                    <div class="section-tittle">
                        <h2>What Services you will Get from us!</h2>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="properties__button">
                                                                  
                        <nav>
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="false"> All</a>
                                <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false"> Branding</a>
                                <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Logo</a>
                                <a class="nav-item nav-link" id="nav-last-tab" data-toggle="tab" href="#nav-last" role="tab" aria-controls="nav-contact" aria-selected="false">UI/UX </a>
                                <a class="nav-item nav-link" id="nav-technology" data-toggle="tab" href="#nav-techno" role="tab" aria-controls="nav-contact" aria-selected="false">Web Design</a>
                            </div>
                        </nav>
                       
                    </div>
                </div>
            </div>
       </div>
        <div class="row">
            <div class="col-12">
              
                <div class="tab-content active" id="nav-tabContent">
                   
                    <div class="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">           
                        <div class="services-caption">
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-sm-8">
                               
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services1.png" alt="" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <div class="row">
                                        <div class="col-lg-12">
                                         
                                            <div class="single-services mb-30">
                                                <img src="assets/img/service/services2.png" alt="" />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                          
                                            <div class="single-services mb-30">
                                                <img src="assets/img/service/services3.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                 
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services4.png" alt="" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                               
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services5.png" alt="" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                  
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services6.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                    <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div class="services-caption">
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-sm-8">
                               
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services6.png" alt="" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <div class="row">
                                        <div class="col-lg-12">
                                       
                                            <div class="single-services mb-30">
                                                <img src="assets/img/service/services2.png" alt="" />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                         
                                            <div class="single-services mb-30">
                                                <img src="assets/img/service/services3.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services4.png" alt="" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                  
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services5.png" alt="" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                   
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services1.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                        <div class="services-caption">
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-sm-8">
                                   
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services2.png" alt="" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <div class="row">
                                        <div class="col-lg-12">
                                        
                                            <div class="single-services mb-30">
                                                <img src="assets/img/service/services1.png" alt="" />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                        
                                            <div class="single-services mb-30">
                                                <img src="assets/img/service/services3.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                 
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services4.png" alt="" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                 
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services5.png" alt="" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                             
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services6.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div class="tab-pane fade" id="nav-last" role="tabpanel" aria-labelledby="nav-last-tab">
                        <div class="services-caption">
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-sm-8">
                                
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services1.png" alt="" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <div class="row">
                                        <div class="col-lg-12">
                                     
                                            <div class="single-services mb-30">
                                                <img src="assets/img/service/services2.png" alt="" />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                         
                                            <div class="single-services mb-30">
                                                <img src="assets/img/service/services3.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                 
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services4.png" alt="" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                 
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services5.png" alt="" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                  
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services6.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div class="tab-pane fade" id="nav-techno" role="tabpanel" aria-labelledby="nav-technology">
                        <div class="services-caption">
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-sm-8">
                                   
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services3.png" alt="" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <div class="row">
                                        <div class="col-lg-12">
                                           
                                            <div class="single-services mb-30">
                                                <img src="assets/img/service/services2.png" alt="" />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                           
                                            <div class="single-services mb-30">
                                                <img src="assets/img/service/services1.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                 
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services4.png" alt="" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                  
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services5.png" alt="" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                   
                                    <div class="single-services mb-30">
                                        <img src="assets/img/service/services6.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
        </div>
    </div>
</section>
 
<section class="wantToWork-area w-padding2">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-xl-10 col-lg-8 col-md-8">
                <div class="wantToWork-caption wantToWork-caption2">
                    <h2>Dont worry for contact. We are available.</h2><br></br>
                    <h4><b>Email: info@desireglobalsolutions.com</b></h4>
                </div>
            </div>
      
        </div>
    </div>
</section>
 
<section class="client-comments section-paddingt30">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-8 col-md-10">
             
                <div class="section-tittle mb-70">
                    <h2>Some Possitive Feedback  That Encourage Us</h2>
                </div>
            </div>
        </div>
    </div>
 
    <div class="latest-blog-area">
        <div class="container">
            <div class="row">
                 
                   
                    <div class="col-xl-4">
                        <div class="blog-wrapper">
                            <div class="blog-inner">
                            <div class="blog-top">
                                    <div class="person-img">
                                        <img src="assets/img/gallery/blog1.png" alt="" />
                                    </div>
                                    <div class="comment-person">
                                        <h2>Alysa O</h2>   
                                        <span>CEO</span>
                                    </div>
                            </div>
                                <p>After using Website Development my business skyrocketed! Website Development 
                                    is both attractive and highly adaptable.</p>
                            </div>
                        </div>
                    </div>
              
                    <div class="col-xl-4">
                        <div class="blog-wrapper">
                            <div class="blog-inner">
                            <div class="blog-top">
                                    <div class="person-img">
                                        <img src="assets/img/gallery/blog2.png" alt="" />
                                    </div>
                                    <div class="comment-person">
                                        <h2>Yolande R</h2>   
                                        <span>CEO</span>
                                    </div>
                            </div>
                                <p>The Desire Global Solutions service was excellent. 
                                    We can't understand how we've been living without Digital Marketing.</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-xl-4">
                        <div class="blog-wrapper">
                            <div class="blog-inner">
                            <div class="blog-top">
                                    <div class="person-img">
                                        <img src="assets/img/gallery/blog3.png" alt="" />
                                    </div>
                                    <div class="comment-person">
                                        <h2>Salome O</h2>   
                                        <span>CEO</span>
                                    </div>
                            </div>
                                <p>You guys rock! I was amazed at the quality of DevOps. DevOps did exactly what you said it does. 
                                    This is simply unbelievable!</p>
                            </div>
                        </div>
                    </div>
                     
                     
                 
            </div>
        </div>
    </div>           
 
</section>
  

 <section class="contact-info-area w-padding2 bg-info" data-background="assets/img/gallery/section_bg04.jpg">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-xl-5 col-lg-7">
              <div class="contact-caption mb-50">
                    <h3>If Not Now, When? Let’s Work Together!</h3>
                    {/* <p>Consectetur adipiscing elit, sed do eiusmod tempor ididunt ut labore et dolore magna aliqua. 
                        Quis ipsum suspendisces gravida. Risus commodo viverra.</p>
                    <img src="assets/img/gallery/sin.png" alt="" /> */}
              </div>
            </div>
            {/* <div class="col-xl-5 col-lg-5">
                <form action="#" class="contact-wrapper">
                    <input type="text" name="name" placeholder="Full Name" />
                    <input type="email" name="email" placeholder="Email Address" />
                    <textarea name="message" id="message" placeholder="Your Message"></textarea>
                    <button class="submit-btn2" type="submit">Send Message</button>
                </form>
            </div> */}
        </div>
        
    </div>
</section> 

 

</main>

 


               </div>
          )
     }
}

export default Home 
import React,{ Component } from 'react'
import {BrowserRouter as Link } from "react-router-dom";

class Services extends Component {
     render(){
          return(
               <div>
                 
                 
                 <main>

 
<div class="slider-area ">
    <div class="slider-height2 d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="hero-cap">
                        <h2>Our Services</h2>
                        <nav aria-label="breadcrumb ">
                            <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li class="breadcrumb-item"><a href="#">Our Services</a></li> 
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 
<section class="categories-area categories-area2  section-padding30">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-cat text-center mb-50">
                    <div class="cat-icon">
                    <img style={{ width: 75, height: 75 }} src="assets/img/service/agile.png"  />
                    </div>
                    <div class="cat-cap">
                        <h5><a>DevOps Solutions</a></h5>
                        <p>We worked in all kind of DevOps solutions. We covered best solutions as per requirements. Working on 
                            latest DevOps tools according to global deisre.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-cat text-center mb-50">
                    <div class="cat-icon">
                    <img style={{ width: 75, height: 75 }} src="assets/img/service/hosting.png"  />
                    </div>
                    <div class="cat-cap">
                        <h5><a>Web Hosting Services</a></h5>
                        <p>We Provide Hosting Services on Cloud. Providing Cloud Services according to global desire.
                             Managed Services provided according to project requirements.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-cat text-center mb-50">
                    <div class="cat-icon">
                    <img style={{ width: 75, height: 75 }} src="assets/img/service/shopping.png"  />
                    </div>
                    <div class="cat-cap">
                        <h5><a>Web/Ecommerce Solutions</a></h5>
                        <p>Website/Ecommerce build for growth of any company/business. We build best websites according to 
                            global desire. Our websites solutions can be upgrade easily.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-cat text-center mb-50">
                    <div class="cat-icon">
                    <img style={{ width: 75, height: 75 }} src="assets/img/service/bullhorn.png"  />
                    </div>
                    <div class="cat-cap">
                        <h5><a>Digital Marketing</a></h5>
                        <p>We make Digital Ideas to grow company business. Our digital ideas worked according to global desire.
                             </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-cat text-center mb-50">
                    <div class="cat-icon">
                    <img style={{ width: 75, height: 75 }} src="assets/img/service/app-development.png"  />
                    </div>
                    <div class="cat-cap">
                        <h5><a>Software Development</a></h5>
                        <p>Applying Data Science and Machine Learning Techniques. We build softwares according to global desire.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-cat text-center mb-50">
                    <div class="cat-icon">
                    <img style={{ width: 75, height: 75 }} src="assets/img/service/seo.png"  />
                    </div>
                    <div class="cat-cap">
                        <h5><a>SEO/PPC Solutions</a></h5>
                        <p>SEO & PPC solutions are avaialable. Our Search Engine Optimazation solutions acording to global desire.
                        </p>
                    </div>
                </div>
            </div>
         </div>
    </div>
</section>
 
<section class="wantToWork-area w-padding2">
    <div class="container">
    <div class="row align-items-center justify-content-between">
            <div class="col-xl-10 col-lg-8 col-md-8">
                <div class="wantToWork-caption wantToWork-caption2">
                    <h2>Dont worry for contact. We are available.</h2><br></br>
                    <h4><b>Email: info@desireglobalsolutions.com</b></h4>
                </div>
            </div>
      
        </div>
    </div>
</section>
 
 
 
<div class="contact-info-area w-padding2 bg-info" data-background="assets/img/gallery/section_bg04.jpg">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-xl-5 col-lg-7">
              <div class="contact-caption mb-50">
                    <h3>If Not Now, When? Let’s Work Together!</h3>
                    {/* <p>Consectetur adipiscing elit, sed do eiusmod tempor ididunt ut labore et dolore magna aliqua. Quis ipsum suspendisces gravida. Risus commodo viverra.</p>
                    <img src="assets/img/gallery/sin.png" alt="" /> */}
              </div>
            </div>
            {/* <div class="col-xl-5 col-lg-5">
                <form action="#" class="contact-wrapper">
                    <input type="text" name="name" placeholder="Full Name" />
                    <input type="email" name="email" placeholder="Email Address" />
                    <textarea name="message" id="message" placeholder="Your Message"></textarea>
                    <button class="submit-btn2" type="submit">Send Message</button>
                </form>
            </div> */}
        </div>
        WhatsApp: +92 3009413488
        <br></br>
        Email: info@desireglobalsolutions.com
    </div>
</div>
 

</main>






               </div>
          )
     }
}

export default Services 

import React,{ Component } from 'react'
import {BrowserRouter as Router,Switch,Route,Link } from "react-router-dom";
import Home from "../components/Home";
import About from "../components/About";
import Services from "../components/Services";
import Portfolio from "../components/Portfolio";
import Contact from "../components/Contact";
import Blog from "../components/Blog";
import Blogdetails from "../components/Blogdetails";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {LinkContainer} from 'react-router-bootstrap';



  
    
  





class Header extends Component {
     render(){
          return(
               <Router>
               <div>

<div id="preloader-active">
        <div class="preloader d-flex align-items-center justify-content-center">
            <div class="preloader-inner position-relative">
                <div class="preloader-circle"></div>
                <div class="preloader-img pere-text">
                    <img src="assets/img/logo/loder.jpg" alt="" />
                </div>
            </div>
        </div>
    </div>
   
    <header>


 
      
 



    <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
      <Container>
        {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}

        <Navbar.Brand><Link to="/"><img src="assets/img/logo/logo.png" alt="Desire Global Solutions Logo" /></Link></Navbar.Brand>
        
      
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          
     <Nav className="me-auto">

          <LinkContainer to="/">
               <Nav.Link>Home</Nav.Link>
          </LinkContainer>

          <LinkContainer to="/about">
               <Nav.Link>About</Nav.Link>
          </LinkContainer>

          <LinkContainer to="/services">
               <Nav.Link>Services</Nav.Link>
          </LinkContainer>

          <LinkContainer to="/portfolio">
               <Nav.Link>Portfolio</Nav.Link>
          </LinkContainer>

          <LinkContainer to="/contact">
               <Nav.Link>Contact</Nav.Link>
          </LinkContainer>


     </Nav>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  




         
        
        
    </header>

    <Switch>
          <Route exact path="/" component={Home} /> 
          <Route exact path="/about" component={About} /> 
          <Route exact path="/services" component={Services} /> 
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/blogdetails" component={Blogdetails} />
          

        </Switch>



               </div>
               </Router>
          )
     }
}

export default Header 